import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloudinaryUploadWidget from 'src/components/CloudinaryUploadWidget';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import { harvardActivitiesList } from 'src/constants/harvardActivitiesList';

interface OfficerPosition {
  name: string;
  position: string;
}

function OrganizationBioform() {
  const navigate = useNavigate();

  const [orgName, setOrgName] = useState<string | null>(null);
  const [orgPhoto, setOrgPhoto] = useState<string>('');
  const [orgEmail, setOrgEmail] = useState<string>('');

  const [officerName, setOfficerName] = useState<string>('');
  const [officerPosition, setOfficerPosition] = useState<string>('');
  const [officers, setOfficers] = useState<OfficerPosition[]>([]);

  const [orgDescription, setOrgDescription] = useState<string>('');

  const [submittingInfo, setSubmittingInfo] = useState<boolean>(false);

  const submitBioform = () => {
    if (submittingInfo) return;
    setSubmittingInfo(true);

    const validators = {
      orgName: orgName && orgName.length > 0,
      orgPhoto: orgPhoto.length > 0,
    };

    if (!Object.values(validators).every((v) => v)) {
      // eslint-disable-next-line no-alert
      alert('Please fill out all required fields');
      setSubmittingInfo(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/organizations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orgName,
        orgPhoto,
        orgEmail,
        officers,
        orgDescription,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          if (data.data.url && data.data.url !== '') {
            window.location = data.data.url;
          } else {
            navigate('/success_orgbioform', { replace: true });
          }
        } else {
          // eslint-disable-next-line no-alert
          alert('There was an error submitting your information');
        }
        setSubmittingInfo(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(
          'There was an error submitting your information. Please try again later.',
        );
        setSubmittingInfo(false);
      });
  };

  return (
    <>
      <Header />

      <Box
        sx={{
          width: '100%',
          pt: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Student Organization Bioform</Typography>
      </Box>

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please fill out the form below in order to reserve your space in
              the 2025 Yearbook, <i>Harvard 389</i>. This information will
              appear next to your organization&apos;s group photo. Note that
              filling out this form alone does <b>not</b> reserve your
              organization&apos;s spot in the yearbook; your organization must
              also have a group photo.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Final changes to this form will be accepted only until December
              8th, 2024 11:59PM. If you need to modify your information after
              you have already submitted the form, please complete and submit
              this form again and your new information will replace your
              previous submission.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Organization Name</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              The groups listed below are officially endorsed each year by the
              Dean of Students Office and the Harvard Athletics Department. If
              you cannot find your name in the list, please email
              <a href="mailto:inquiries@harvardyearbook.com">
                inquiries@harvardyearbook.com
              </a>{' '}
              for assistance.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Due to publication constraints, the word &quot;Harvard&quot; will
              be included in the name of the student group ONLY if the activity
              is a media or publication. If you prefer to use an acronym rather
              than the full name, please email{' '}
              <a href="mailto:inquiries@harvardyearbook.com">
                inquiries@harvardyearbook.com
              </a>
              with your request.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              value={orgName}
              getOptionDisabled={(option) => option === orgName}
              onChange={(_e, newValue) => setOrgName(newValue)}
              options={harvardActivitiesList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="organization-name"
                  label="Organization Name"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Organization Photo</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              A picture is required in order to appear in the Harvard Yearbook.
              You may upload a high-resolution group photo below by December
              8th, 2024 11:59PM.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <CloudinaryUploadWidget
              uwConfig={{
                cloudName: 'harvard-yearbook',
                uploadPreset: 'b266p2uw',
                folder: 'groups_389',
              }}
              fileName={orgName || ''}
              openWidget={orgName !== ''}
              setPhotoLink={setOrgPhoto}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Organization Email</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Please submit an email for which your organization can be reached
              at. We recommend using a group email address if one is available,
              or you may choose to use an email of one of your officers
              (preferably @college.harvard.edu) if that is the case. Please note
              that we will not disclose your email to anyone else and this email
              will only be used for confirmation of your submission and if any
              of our yearbook staff need to reach out to you regarding the
              organization bioform submission.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="org-email"
              label="Organization Email"
              variant="outlined"
              value={orgEmail}
              onChange={(e) => setOrgEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Officer Information</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              In the space below, enter your organization&apos;s officer
              information in order of decreasing importance. They will appear in
              the yearbook in the following format:
            </Typography>

            <Typography variant="body1">
              President: John Harvard; Vice President: Jane Doe.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              We need an up-to-date list of the group&apos;s board members. If
              you feel that the board that immediately preceded the current one
              is more representative of the graduating class, you may submit
              that one instead. Please only include official board positions.
              Note that the officer listing may be edited due to space
              limitations, especially if your organization submits a list of 15
              or more officers.
            </Typography>
          </Grid>

          {officers.length > 0 && (
            <Grid item xs={12}>
              <List
                sx={{
                  padding: 0,
                }}
              >
                {officers.map((officer, ind) => (
                  <ListItem key={ind}>
                    <ListItemIcon
                      onClick={(_e) =>
                        setOfficers(officers.filter((_, i) => i !== ind))
                      }
                    >
                      <ClearIcon
                        style={{
                          cursor: 'pointer',
                          color: 'red',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={officer.name}
                      secondary={officer.position}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}

          <Grid container item xs={12} columnSpacing={1}>
            <Grid item xs={5.5}>
              <TextField
                fullWidth
                id="officer-name"
                label="Officer Name"
                variant="outlined"
                value={officerName}
                onChange={(e) => setOfficerName(e.target.value)}
              />
            </Grid>

            <Grid item xs={5.5}>
              <TextField
                fullWidth
                id="officer-position"
                label="Officer Position"
                variant="outlined"
                value={officerPosition}
                onChange={(e) => setOfficerPosition(e.target.value)}
              />
            </Grid>

            <Grid item xs={1}>
              <Button
                fullWidth
                variant="contained"
                disabled={officerName === '' || officerPosition === ''}
                onClick={() => {
                  if (officerName === '' || officerPosition === '') return;

                  setOfficers([
                    ...officers,
                    {
                      name: officerName,
                      position: officerPosition,
                    },
                  ]);
                  setOfficerName('');
                  setOfficerPosition('');
                }}
                sx={{
                  height: '100%',
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Brief Organization Description</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              This blurb can address briefly what the group is all about as well
              as any new or interesting events for the group that distinguish
              this school year or the contributions by members of the graduating
              class.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              id="organization-description"
              label="Organization Description"
              variant="outlined"
              value={orgDescription}
              onChange={(e) => setOrgDescription(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={submitBioform}>
              {submittingInfo ? <CircularProgress /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </>
  );
}

export default OrganizationBioform;
