export const concentrationList = [
  'African and African American Studies',
  'Anthropology',
  'Applied Mathematics',
  'Art, Film, and Visual Studies',
  'Astrophysics',
  'Biomedical Engineering',
  'Chemical and Physical Biology',
  'Chemistry',
  'Chemistry and Physics',
  'Classics',
  'Comparative Literature',
  'Comparative Study of Religion',
  'Computer Science',
  'Earth and Planetary Sciences',
  'East Asian Studies',
  'Economics',
  'Electrical Engineering',
  'English',
  'Environmental Science and Engineering',
  'Environmental Science and Public Policy',
  'Folklore and Mythology',
  'Germanic Languages and Literatures',
  'Government',
  'History',
  'History & Literature',
  'History and Science',
  'History of Art and Architecture',
  'Human Developmental and Regenerative Biology',
  'Human Evolutionary Biology',
  'Integrative Biology',
  'Linguistics',
  'Mathematics',
  'Mechanical Engineering',
  'Molecular and Cellular Biology',
  'Music',
  'Near Eastern Languages and Civilizations',
  'Neuroscience',
  'Philosophy',
  'Physics',
  'Psychology',
  'Romance Languages and Literatures',
  'Slavic Languages and Literatures',
  'Social Studies',
  'Sociology',
  'South Asian Studies',
  'Special Concentrations',
  'Statistics',
  'Theater, Dance, & Media',
  'Studies of Women, Gender, and Sexuality',
];

export const sbConcentrationsList = [
  'Electrical Engineering',
  'Environmental Science and Engineering',
  'Mechanical Engineering',
];
