import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

dayjs.extend(dayOfYear);

interface Row {
  submissionId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  secondarySchool: string;
  country: string;
  state: string;
  city: string;
  privacyOption: number;
  email: string;
  phone: string;
  photoLink: string;
  orderCount: number;
  parentAd: string;
  orderTotal: number;
  paymentStatus: string;
  pickedUpButton: string;
  editButton: string;
}

function FreshmanRegisterOrders() {
  const [requestedData, setRequestedData] = useState<boolean>(false);
  const [searchYear, setSearchYear] = useState<number | null>(null);
  const [downloadYear, setDownloadYear] = useState<number | null>(null);

  const [rows, setRows] = useState<Row[]>([]);

  const [openEditMenu, setOpenEditMenu] = useState<boolean>(false);
  const [openPickedUpMenu, setOpenPickedUpMenu] = useState<boolean>(false);
  const [openPickedUpRemoveMenu, setOpenPickedUpRemoveMenu] =
    useState<boolean>(false);
  const [editRow, setEditRow] = useState<Row | null>(null);

  const downloadYearRegistersParams = useMemo(() => {
    const params = new URLSearchParams();
    params.append('year', JSON.stringify(downloadYear));

    return params;
  }, [downloadYear]);

  useEffect(() => {
    if (!requestedData) return;

    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/freshman_registers`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        year: searchYear,
      }),
    })
      .then((res) => res.json())
      .then((res) => setRows(res.data.rows));
  }, [requestedData]);

  const columns: readonly GridColDef[] = [
    { field: 'submissionId', headerName: 'Submission ID', minWidth: 170 },
    { field: 'firstName', headerName: 'First Name', minWidth: 170 },
    { field: 'middleName', headerName: 'Middle Name', minWidth: 170 },
    { field: 'lastName', headerName: 'Last Name', minWidth: 170 },
    { field: 'preferredName', headerName: 'Preferred Name', minWidth: 170 },
    { field: 'secondarySchool', headerName: 'Secondary School', minWidth: 170 },
    { field: 'country', headerName: 'Country', minWidth: 100 },
    { field: 'state', headerName: 'State (US)', minWidth: 50 },
    { field: 'city', headerName: 'City', minWidth: 100 },
    { field: 'privacyOption', headerName: 'Privacy Option', minWidth: 100 },
    { field: 'email', headerName: 'Email', minWidth: 170 },
    { field: 'phone', headerName: 'Phone', minWidth: 170 },
    { field: 'photoLink', headerName: 'Photo Link', minWidth: 170 },
    { field: 'orderCount', headerName: '# Ordered FRs', minWidth: 170 },
    { field: 'parentAd', headerName: 'Parent Ad', minWidth: 170 },
    { field: 'orderTotal', headerName: 'Order Total', minWidth: 170 },
    { field: 'paymentStatus', headerName: 'Payment Status', minWidth: 170 },
    {
      field: 'pickedUpButton',
      headerName: 'Picked Up?',
      minWidth: 100,
      cellClassName: 'pickedUpButton',
      renderCell: (row: GridRenderCellParams<Row>) => {
        if (row.row.paymentStatus === 'paid') {
          if (row.value) {
            return (
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  setOpenPickedUpRemoveMenu(true);
                  setEditRow(row.row);
                }}
              >
                {row.value}
              </Button>
            );
          }

          return (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setOpenPickedUpMenu(true);
                setEditRow(row.row);
              }}
            >
              Pick Up
            </Button>
          );
        }

        return null;
      },
    },
    {
      field: 'edit',
      type: 'actions',
      headerName: 'Edit',
      minWidth: 50,
      cellClassName: 'edit',
      getActions: ({ row }: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => {
            setOpenEditMenu(true);
            setEditRow(row);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Freshman Register Orders</Typography>
      </Box>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setRequestedData(true);
            }}
          >
            Get Orders for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={searchYear}
            onChange={(e) => {
              setSearchYear(parseInt(e.target.value, 10));
              setRequestedData(false);
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            href={`${
              process.env.REACT_APP_BACKEND_URL
            }/staff/download_registers${
              downloadYearRegistersParams.size > 0 ? '?' : ''
            }${downloadYearRegistersParams.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            Download CSV for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={downloadYear}
            onChange={(e) => {
              setDownloadYear(parseInt(e.target.value, 10));
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      {requestedData && (
        <Box
          sx={{
            pl: '10%',
            width: '80%',
            pt: 4,
            display: 'grid',
            height: '800px',
          }}
        >
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            ignoreValueFormatterDuringExport
            rows={rows}
            columns={columns}
            getRowId={(row) => row.submissionId}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  fileName: `freshman_register_orders_${searchYear}`,
                },
              },
            }}
          />
        </Box>
      )}

      <Dialog
        open={openPickedUpMenu}
        scroll="body"
        maxWidth="xs"
        onClose={() => setOpenPickedUpMenu(false)}
      >
        <DialogTitle>
          Are you sure that you want to mark this as picked up?
        </DialogTitle>
        <DialogActions>
          <Button color="error" onClick={() => setOpenPickedUpMenu(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_register/pickup`,
                  {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenPickedUpMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_registers`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPickedUpRemoveMenu}
        scroll="body"
        maxWidth="xs"
        onClose={() => setOpenPickedUpRemoveMenu(false)}
      >
        <DialogTitle>
          Are you sure that you want to mark this as *not* picked up?
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            onClick={() => setOpenPickedUpRemoveMenu(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_register/pickup`,
                  {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenPickedUpRemoveMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_registers`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={openEditMenu}
        scroll="body"
        maxWidth="sm"
        onClose={() => setOpenEditMenu(false)}
      >
        <DialogTitle>Edit Freshman Register Bioform</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update this person&apos;s bioform.
          </DialogContentText>

          <br />

          <Stack direction="column" spacing={2}>
            <TextField
              label="Submission ID"
              value={editRow?.submissionId}
              disabled
            />
            <TextField
              label="First Name"
              value={editRow?.firstName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, firstName: e.target.value });
              }}
            />
            <TextField
              label="Middle Name"
              value={editRow?.middleName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, middleName: e.target.value });
              }}
            />
            <TextField
              label="Last Name"
              value={editRow?.lastName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, lastName: e.target.value });
              }}
            />
            <TextField
              label="Preferred Name"
              value={editRow?.preferredName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, preferredName: e.target.value });
              }}
            />
            <TextField
              label="Secondary School"
              value={editRow?.secondarySchool}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, secondarySchool: e.target.value });
              }}
            />
            <TextField
              label="Country"
              value={editRow?.country}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, country: e.target.value });
              }}
            />
            <TextField
              label="State"
              value={editRow?.state}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, state: e.target.value });
              }}
            />
            <TextField
              label="City"
              value={editRow?.city}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, city: e.target.value });
              }}
            />
            <TextField
              label="Privacy Option"
              value={editRow?.privacyOption}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    privacyOption: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="Email"
              value={editRow?.email}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, email: e.target.value });
              }}
            />
            <TextField
              label="Phone"
              value={editRow?.phone}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, phone: e.target.value });
              }}
            />
            <TextField
              label="Order Count"
              value={editRow?.orderCount}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    orderCount: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="Parent Ad"
              value={editRow?.parentAd}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentAd: e.target.value });
              }}
            />
            <TextField
              label="Order Total"
              value={editRow?.orderTotal}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    orderTotal: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="Payment Status"
              value={editRow?.paymentStatus}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, paymentStatus: e.target.value });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditMenu(false)}>Cancel</Button>
          <Button
            color="error"
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_register/payment`,
                  {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_registers`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Remove Payment
          </Button>
          <Button
            color="error"
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_register`,
                  {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_registers`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_register`,
                  {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                      firstName: editRow.firstName,
                      middleName: editRow.middleName,
                      lastName: editRow.lastName,
                      preferredName: editRow.preferredName,
                      secondarySchool: editRow.secondarySchool,
                      country: editRow.country,
                      state: editRow.state,
                      city: editRow.city,
                      privacyOption: editRow.privacyOption,
                      email: editRow.email,
                      phone: editRow.phone,
                      orderCount: editRow.orderCount,
                      orderTotal: editRow.orderTotal,
                      parentAd: editRow.parentAd,
                      paymentStatus: editRow.paymentStatus,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/freshman_registers`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FreshmanRegisterOrders;
