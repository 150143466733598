import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
  DataGrid,
  GridToolbar,
} from '@mui/x-data-grid';
import { useState, useMemo, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';

interface ExtracurricularPosition {
  club: string;
  position: string;
}

interface Row {
  submissionId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  house: string;
  classYear: number;
  email: string;
  alternateEmail: string;
  phone: string;
  studyType: number;
  concentration: string;
  concentrationSB: boolean;
  secondConcentration: string;
  secondConcentrationSB: boolean;
  secondary: string;
  languageCitation: string;
  specialConcentration: string;
  concurrentMasters: string;
  professorFirstName: string;
  professorLastName: string;
  professorDepartment: string;
  professorEmail: string;
  birthDate: string;
  country: string;
  state: string;
  city: string;
  secondarySchool: string;
  academicHonors: string[];
  intercollegiateSports: ExtracurricularPosition[];
  houseActivities: ExtracurricularPosition[];
  harvardActivities: ExtracurricularPosition[];
  clubSports: ExtracurricularPosition[];
  onCampusJobs: ExtracurricularPosition[];
  editButton: string;
}

function YearbookBioforms() {
  const [requestedData, setRequestedData] = useState<boolean>(false);
  const [searchYear, setSearchYear] = useState<number | null>(null);
  const [downloadYear, setDownloadYear] = useState<number | null>(null);

  const [rows, setRows] = useState<Row[]>([]);

  const [openEditMenu, setOpenEditMenu] = useState<boolean>(false);
  const [editRow, setEditRow] = useState<Row | null>(null);

  const downloadYearRegistersParams = useMemo(() => {
    const params = new URLSearchParams();
    params.append('year', JSON.stringify(downloadYear));

    return params;
  }, [downloadYear]);

  useEffect(() => {
    if (!requestedData) return;

    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        year: searchYear,
      }),
    })
      .then((res) => res.json())
      .then((res) => setRows(res.data.rows));
  }, [requestedData]);

  const columns: readonly GridColDef[] = [
    { field: 'submissionId', headerName: 'Submission ID', minWidth: 170 },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 170,
    },
    {
      field: 'middleName',
      headerName: 'Middle Name',
      minWidth: 170,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 170,
    },
    {
      field: 'suffix',
      headerName: 'Suffix',
      minWidth: 170,
    },
    {
      field: 'house',
      headerName: 'House',
      minWidth: 170,
    },
    {
      field: 'classYear',
      headerName: 'Class Year',
      minWidth: 170,
    },
    { field: 'email', headerName: 'Email', minWidth: 170 },
    {
      field: 'alternateEmail',
      headerName: 'Alternate Email',
      minWidth: 170,
    },
    { field: 'phone', headerName: 'Phone', minWidth: 170 },
    {
      field: 'studyType',
      headerName: 'Study Type',
      minWidth: 170,
    },
    {
      field: 'concentration',
      headerName: 'Concentration',
      minWidth: 170,
    },
    {
      field: 'concentrationSB',
      headerName: 'Concentration SB?',
      minWidth: 170,
    },
    {
      field: 'secondConcentration',
      headerName: 'Second Concentration',
      minWidth: 170,
    },
    {
      field: 'secondConcentrationSB',
      headerName: 'Second Concentration SB?',
      minWidth: 170,
    },
    {
      field: 'secondary',
      headerName: 'Secondary',
      minWidth: 170,
    },
    {
      field: 'languageCitation',
      headerName: 'Language Citation',
      minWidth: 170,
    },
    {
      field: 'specialConcentration',
      headerName: 'Special Concentration',
      minWidth: 170,
    },
    {
      field: 'concurrentMasters',
      headerName: 'Concurrent Masters',
      minWidth: 170,
    },
    {
      field: 'professorFirstName',
      headerName: 'Professor First Name',
      minWidth: 170,
    },
    {
      field: 'professorLastName',
      headerName: 'Professor Last Name',
      minWidth: 170,
    },
    {
      field: 'professorDepartment',
      headerName: 'Professor Department',
      minWidth: 170,
    },
    {
      field: 'professorEmail',
      headerName: 'Professor Email',
      minWidth: 170,
    },
    {
      field: 'birthDate',
      headerName: 'Birth Date',
      minWidth: 170,
    },
    {
      field: 'city',
      headerName: 'City',
      minWidth: 170,
    },
    {
      field: 'state',
      headerName: 'State',
      minWidth: 170,
    },
    {
      field: 'country',
      headerName: 'Country',
      minWidth: 170,
    },
    {
      field: 'secondarySchool',
      headerName: 'Secondary School',
      minWidth: 170,
    },
    {
      field: 'academicHonors',
      headerName: 'Academic Honors',
      minWidth: 170,
      valueGetter: (value: string[]) => value.join(', '),
    },
    {
      field: 'intercollegiateSports',
      headerName: 'Intercollegiate Sports',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity: ExtracurricularPosition) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'houseActivities',
      headerName: 'House Activities',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity: ExtracurricularPosition) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'harvardActivities',
      headerName: 'Harvard Activities',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'clubSports',
      headerName: 'Club Sports',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'onCampusJobs',
      headerName: 'On Campus Jobs',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'finalBioform',
      headerName: 'Bioform',
      minWidth: 170,
    },
    {
      field: 'edit',
      type: 'actions',
      headerName: 'Edit',
      minWidth: 50,
      cellClassName: 'edit',
      getActions: ({ row }: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => {
            setOpenEditMenu(true);
            setEditRow(row);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Yearbook Bioforms</Typography>
      </Box>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setRequestedData(true);
            }}
          >
            Get Bioforms for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={searchYear}
            onChange={(e) => {
              setSearchYear(parseInt(e.target.value, 10));
              setRequestedData(false);
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            href={`${
              process.env.REACT_APP_BACKEND_URL
            }/staff/download_yearbook_bioforms${
              downloadYearRegistersParams.size > 0 ? '?' : ''
            }${downloadYearRegistersParams.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            Download CSV for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={downloadYear}
            onChange={(e) => {
              setDownloadYear(parseInt(e.target.value, 10));
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      {requestedData && (
        <Box
          sx={{
            pl: '10%',
            width: '80%',
            pt: 4,
            display: 'grid',
            height: '800px',
          }}
        >
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            rows={rows}
            columns={columns}
            getRowId={(row) => row.submissionId}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  fileName: `yearbook_bioforms_${searchYear}`,
                },
              },
            }}
          />
        </Box>
      )}

      <Dialog
        fullWidth
        open={openEditMenu}
        scroll="body"
        maxWidth="sm"
        onClose={() => setOpenEditMenu(false)}
      >
        <DialogTitle>Edit Yearbook Bioform</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update this person&apos;s bioform.
          </DialogContentText>

          <br />

          <Stack direction="column" spacing={2}>
            <TextField
              label="Submission ID"
              value={editRow?.submissionId}
              disabled
            />
            <TextField
              label="First Name"
              value={editRow?.firstName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, firstName: e.target.value });
              }}
            />
            <TextField
              label="Middle Name"
              value={editRow?.middleName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, middleName: e.target.value });
              }}
            />
            <TextField
              label="Last Name"
              value={editRow?.lastName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, lastName: e.target.value });
              }}
            />
            <TextField
              label="Suffix"
              value={editRow?.suffix}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, suffix: e.target.value });
              }}
            />
            <TextField
              label="House"
              value={editRow?.house}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, house: e.target.value });
              }}
            />
            <TextField
              label="Class Year"
              value={editRow?.classYear}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    classYear: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="Email"
              value={editRow?.email}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, email: e.target.value });
              }}
            />
            <TextField
              label="Alternate Email"
              value={editRow?.alternateEmail}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, alternateEmail: e.target.value });
              }}
            />
            <TextField
              label="Phone"
              value={editRow?.phone}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, phone: e.target.value });
              }}
            />
            <TextField
              label="Study Type"
              value={editRow?.studyType}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    studyType: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="Concentration"
              value={editRow?.concentration}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, concentration: e.target.value });
              }}
            />
            <FormControlLabel
              label="Bachelor's of Science"
              control={
                <Checkbox
                  checked={editRow?.concentrationSB}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (editRow) {
                      setEditRow({
                        ...editRow,
                        concentrationSB: e.target.checked,
                      });
                    }
                  }}
                />
              }
            />
            <TextField
              label="Second Concentration"
              value={editRow?.secondConcentration}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    secondConcentration: e.target.value,
                  });
              }}
            />
            <FormControlLabel
              label="Bachelor's of Science"
              control={
                <Checkbox
                  checked={editRow?.secondConcentrationSB}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (editRow) {
                      setEditRow({
                        ...editRow,
                        secondConcentrationSB: e.target.checked,
                      });
                    }
                  }}
                />
              }
            />
            <TextField
              label="Secondary"
              value={editRow?.secondary}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, secondary: e.target.value });
              }}
            />
            <TextField
              label="Second Concentration"
              value={editRow?.secondConcentration}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    secondConcentration: e.target.value,
                  });
              }}
            />
            <TextField
              label="Language Citations"
              value={editRow?.languageCitation}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, languageCitation: e.target.value });
              }}
            />
            <TextField
              label="Special Concentration"
              value={editRow?.specialConcentration}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    specialConcentration: e.target.value,
                  });
              }}
            />
            <TextField
              label="Birth Date"
              value={editRow?.birthDate}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, birthDate: e.target.value });
              }}
            />
            <TextField
              label="City"
              value={editRow?.city}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, city: e.target.value });
              }}
            />
            <TextField
              label="State"
              value={editRow?.state}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, state: e.target.value });
              }}
            />
            <TextField
              label="Country"
              value={editRow?.country}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, country: e.target.value });
              }}
            />
            <TextField
              label="Secondary School"
              value={editRow?.secondarySchool}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, secondarySchool: e.target.value });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditMenu(false)}>Cancel</Button>
          <Button
            color="error"
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`,
                  {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`,
                  {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                      firstName: editRow.firstName,
                      middleName: editRow.middleName,
                      lastName: editRow.lastName,
                      suffix: editRow.suffix,
                      house: editRow.house,
                      classYear: editRow.classYear,
                      email: editRow.email,
                      alternateEmail: editRow.alternateEmail,
                      phone: editRow.phone,
                      studyType: editRow.studyType,
                      concentration: editRow.concentration,
                      concentrationSB: editRow.concentrationSB,
                      secondConcentration: editRow.secondConcentration,
                      secondConcentrationSB: editRow.secondConcentrationSB,
                      secondary: editRow.secondary,
                      languageCitation: editRow.languageCitation,
                      specialConcentration: editRow.specialConcentration,
                      concurrentMasters: editRow.concurrentMasters,
                      professorFirstName: editRow.professorFirstName,
                      professorLastName: editRow.professorLastName,
                      professorDepartment: editRow.professorDepartment,
                      professorEmail: editRow.professorEmail,
                      birthDate: editRow.birthDate,
                      country: editRow.country,
                      state: editRow.state,
                      city: editRow.city,
                      secondarySchool: editRow.secondarySchool,
                      academicHonors: editRow.academicHonors,
                      intercollegiateSports: editRow.intercollegiateSports,
                      houseActivities: editRow.houseActivities,
                      harvardActivities: editRow.harvardActivities,
                      clubSports: editRow.clubSports,
                      onCampusJobs: editRow.onCampusJobs,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default YearbookBioforms;
