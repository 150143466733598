export const harvardActivitiesList = [
  'Act On A Dream',
  'Active Minds Student Chapter at CAMHS',
  'Advocating Success for Kids',
  'African Development Group',
  'African Students Association',
  'Albanian Students Association',
  'Alexander Hamilton Society',
  "Alnisa's Medical Alliance",
  'Alpha Omega Christian Club',
  'Alternative Investment Club',
  'An Evening with Champions',
  'Anscombe Society',
  'ApiChorus',
  'Arab Professional Network',
  "Arab Women's Collective",
  'Arkansas Club',
  'Armenian Student Association',
  'Art History Society',
  'Arts & Crafts Club',
  'Asia Program',
  'Asian American Association',
  'Asian American Brotherhood',
  'Asian American Christian Fellowship',
  'Asian American Dance Troupe',
  "Asian American Womxn's Association",
  'Asian Baptist Student Koinonia',
  'Asian Student Arts Project',
  'Asian Varsity Athletes Association',
  'Association Cultivating Inter-American Democracy',
  'Association for U.S.-China Relations',
  'Association of Black Harvard Women',
  'Association of Ghanaian Students',
  'Association of Student Practice and Learning of Yan Xin Life Science & Technology',
  'Astronomy Society',
  'Athlete Ally',
  'Athletics Black Varsity Association',
  'Australian Undergraduate Society',
  'Automotive Society',
  'Bach Society Orchestra',
  "Baha'i Student Association",
  'Ballet Company',
  'Beekeepers',
  'Behavioral Strategy Group',
  'Bengali Association of Students',
  'BGLTQ Business Society',
  'Bhangra',
  'Biomedical Sciences Forum',
  'Biotechnology Club',
  'Bipartisan Solutions',
  'Black Arts Collective',
  'Black Community and Student Theater',
  'Black Community Leaders',
  'Black Consulting',
  'Black Health Advocates',
  "Black Men's Forum",
  'Black Muslim Collective',
  'Black Pre-Law Association',
  'Black Premedical Society',
  'Black Students Association',
  'Blockchain Club',
  'Boardgame and Biosatellite Launch Enterprise',
  'Brattle Street Chamber Players',
  'Brazilian Association',
  'Breakers Organization',
  'British Club',
  'Bulgarian Club',
  'Callbacks',
  'Canadian Club',
  'Candela Latin Dance Troupe',
  'Capital Partners',
  'Caribbean Club',
  'Catholic Student Association',
  'Center for European Studies Student Society',
  'Chabad Undergraduate Board',
  'Chapter of Scholars of Finance',
  'Chapter of University Blood Initiative',
  'Charles River Economics Labs',
  'Chemistry Club',
  'Chess Club',
  'China Forum',
  'Chinese International Students Society',
  'Chinese Students Association',
  'Choir',
  'Christian Fellowship',
  'Christian Impact',
  'Christian Students',
  'CityStep',
  'Classical Club',
  'Clean Energy Group',
  'Climbing Wall',
  'College Events Board',
  'Collegium Musicum',
  'Colombian Students Association',
  'Community of Humanists, Atheists, and Agnostics',
  'Composers Association',
  'Computer Society',
  'Concilio Latino',
  'Conflux',
  'Conservation Society',
  'Constitutional Law Society',
  'Consulting Group',
  'Consulting on Business and the Environment',
  'Contact Peer Counseling',
  'Contemporary Collective',
  'Creative Writing Collective',
  'Crescendo for a Cause',
  'Crimson Key Society',
  'CrimsonEMS',
  'Cuban-American Undergraduate Student Association',
  'Cybersecurity Club',
  'Data Analytics Group',
  'Datamatch',
  'Debate Council',
  'Debating Union',
  'Democracy Matters',
  'Democrats',
  'Design Collective',
  'Dharma',
  'Din & Tonics',
  'Disability Justice Club',
  'Diversity Peer Educators',
  'Dominican Student Association',
  'Dramatic Club',
  'Eating Concerns Hotline and Outreach',
  'Economics Association',
  'Effective Altruism',
  'Electronic Music Collective',
  'Eleganza',
  'Emerging Technology Group',
  'Engineering Society',
  'Engineers Without Borders',
  'Environmental Justice',
  'Eritrean and Ethiopian Students Association',
  'Ethics Society',
  'Expressions Dance Company',
  'Faith and Action',
  'Fallen Angels',
  'Federal Reserve Challenge',
  'FIG. Magazine',
  'Film Club',
  'Financial Analysts Club',
  'First Year Retreat and Experience',
  'First-Year Arts Program',
  'First-Year International Program',
  'First-Year Musical',
  'First-Year Outdoor Program',
  'First-Year Social Committee',
  'First-Year Urban Program',
  'Food Lab',
  'Food Literacy Project',
  'Foreign Policy Initiative',
  'Foundation for International Medical Relief of Children',
  'Francophone Society',
  'Friends of Project Sunshine',
  'Fuerza Latina',
  'Game Developers',
  'Gaming Group',
  'Gender Inclusivity in Mathematics',
  'Generational African American Students Association',
  'Georgian Student Association',
  'German Students Association',
  'Ghungroo',
  'Gilbert & Sullivan Players',
  'Glee Club',
  'Global Alliance for Medical Innovation',
  'Global Education Movement',
  'Global Health Forum',
  'Global Research and Consulting Group',
  "Global Women's Empowerment",
  'Green Medicine Initiative',
  'Greener Scott Scholars Mentorship Program',
  'Group Peer Therapy',
  'GUSH',
  'HackHarvard',
  "Half Asian People's Association",
  'Harvard College Bowl',
  'Harvard Independent',
  'Harvard Lampoon',
  'Harvard Political Review',
  'Harvard Radio Broadcasting',
  'Harvard Review of Philosophy',
  'Harvard Science Review',
  'Harvard Student Agencies',
  'Harvard Undergraduate Economics Review',
  'Harvard Undergraduate Technology Review',
  'Harvard Yearbook Publications',
  'Harvardwood',
  'Hasty Pudding Theatricals',
  "Hawai'i Club",
  'Health Policy Review',
  'Healthcare Associates',
  'Hellenic Society',
  'Hillel',
  'Human Evolutionary Biology Student Club',
  'Hyperion Shakespeare Company',
  'Ichthus',
  'Immediate Gratification Players',
  'Inclusion in Economics',
  'Indigo Magazine',
  'Indigo Peer Counseling',
  'Initiative for Technology and Society',
  'Initiative of Students for Social Prescribing',
  'Institute of Politics',
  'Intercollegiate Model United Nations',
  'Interdisciplinary Immunology Club',
  'Interfaith Forum',
  'International Genetically Engineered Machines',
  'International Relations Council',
  'International Relations on Campus',
  'International Review',
  'Investment Association',
  'Iranian Association',
  'Islamic Society',
  'Italian Society',
  'Japan Initiative',
  'Japan Policy Network',
  'Japan Society',
  'Jazz Bands',
  'John Adams Society',
  'Kesem',
  'KeyChange',
  'Kidney Disease Screening and Awareness Program',
  'Knitting Circle',
  'Korean Association',
  'Korean International Student Association',
  'Krokodiloes',
  'Kuumba Singers',
  'Latina Empowerment and Development',
  'Latinas Unidas',
  'Latines in Health Careers',
  'Latinxs in Finance & Technology',
  'Latter Day Saint Student Association',
  'Law Review',
  'Leadership Institute for the First-Year Experience',
  'Leadership Mentors',
  'Linguistics Society',
  'Lowell House Opera Society',
  'Lowell House Society of Russian Bell Ringers',
  'LowKeys',
  'Maarga',
  'Machine Intelligence Community',
  'MakeHarvard',
  'Mariachi Véritas',
  'Mathematics Association',
  'Medical Humanities Forum',
  'Meditation Club',
  'MEDLIFE',
  'Mentors for Urban Debate',
  'MIHNUET',
  'MIND Relief',
  'Mind, Brain, and Behavior',
  'Minecraft Club',
  'Mock Trial Association',
  'Model Congress',
  'Model Congress Asia',
  'Model Congress Europe',
  'Model Congress Middle East',
  'Model Congress San Francisco',
  'Model United Nations',
  'Modern Dance Company',
  'Mountaineering Club',
  'Mozart Society Orchestra',
  'Musical Theater',
  'National Model United Nations',
  'National Society of Black Physicists',
  'Natives at Harvard',
  'Nepali Student Association',
  'Network of Enlightened Women',
  'Neuroscience Undergraduate Society',
  'Nigerian Students Association',
  'Nordic Club',
  'Noteables',
  'Obstacle Course Racing Club',
  'Omo Naija x The Wahala Boys Dance Troupe',
  'On Thin Ice',
  'Open Data Project',
  'OpenBio Laboratory',
  'Opera Society',
  'Opportunes',
  'Orchestra',
  'Organ Society',
  'Organization for Latin America',
  'Organization of Asian American Sisters in Service',
  'Orthodox Christian Fellowship',
  'Orthodox Student Minyan',
  'Outing Club',
  'Overdose Prevention and Education Students',
  'Pakistani Students Association',
  'Palestine Solidarity Committee',
  'Partners in Health Engage',
  'Patient Support Corps',
  'Pediatric Cancer Buddies',
  'Philippine Forum',
  'Phillips Brooks House Association',
  'Phillips Brooks House Association (Alternative Spring Breaks)',
  "Phillips Brooks House Association (Alzheimer's Buddies Program)",
  'Phillips Brooks House Association (Best Buddies)',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment 1-2-1)',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment Afterschool)',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment Extension)',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment Summer Program)',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment Teen Mentoring)',
  'Phillips Brooks House Association (Boston Refugee Youth Enrichment Tutoring)',
  'Phillips Brooks House Association (Cambridge After-School Program)',
  'Phillips Brooks House Association (Cambridge Afterschool Program)',
  'Phillips Brooks House Association (Cambridge Youth Enrichment Program)',
  'Phillips Brooks House Association (CHANCE)',
  'Phillips Brooks House Association (Chinatown Adventure)',
  'Phillips Brooks House Association (Chinatown Afterschool Program)',
  'Phillips Brooks House Association (Chinatown Big Sibling Program)',
  'Phillips Brooks House Association (Chinatown Citizenship Program)',
  'Phillips Brooks House Association (Chinatown ESL Program)',
  'Phillips Brooks House Association (Chinatown Teen Mentoring)',
  'Phillips Brooks House Association (CIVICS)',
  'Phillips Brooks House Association (Connelly Center Youth Prison Tutoring Program)',
  'Phillips Brooks House Association (David Walker Scholars Program)',
  'Phillips Brooks House Association (Deaf Awareness Club)',
  'Phillips Brooks House Association (Elderly 1-2-1)',
  'Phillips Brooks House Association (Environmental Action Committee)',
  'Phillips Brooks House Association (Experimentors)',
  'Phillips Brooks House Association (Franklin After School Enrichment)',
  'Phillips Brooks House Association (Franklin I-O Summer Program)',
  'Phillips Brooks House Association (Habitat for Humanity)',
  'Phillips Brooks House Association (HARMONY Mentoring)',
  "Phillips Brooks House Association (Harvard Artists for Alzheimer's)",
  'Phillips Brooks House Association (Harvard Square Homeless Shelter)',
  'Phillips Brooks House Association (Housing Opportunities Program)',
  'Phillips Brooks House Association (Keylatch Afterschool Program)',
  'Phillips Brooks House Association (Keylatch Mentor Program)',
  'Phillips Brooks House Association (Keylatch Summer Program)',
  'Phillips Brooks House Association (Kids with Special Needs Achievement Program)',
  'Phillips Brooks House Association (LEADERS! Summer Program)',
  'Phillips Brooks House Association (LEADERS! Term)',
  'Phillips Brooks House Association (Mission Hill Afterschool Program)',
  'Phillips Brooks House Association (Mission Hill Summer Program)',
  'Phillips Brooks House Association (Mission Mentor)',
  'Phillips Brooks House Association (Native American Youth Enrichment Program Summer)',
  'Phillips Brooks House Association (Partners Empowering Neighborhoods)',
  'Phillips Brooks House Association (Peer Health Exchange)',
  'Phillips Brooks House Association (Pets as Therapy)',
  'Phillips Brooks House Association (Recent Immigrant Term-Time Enrichment)',
  'Phillips Brooks House Association (Recreational Experience and Arts Creativity with Harvard)',
  'Phillips Brooks House Association (Refugee Youth Summer Enrichment)',
  'Phillips Brooks House Association (Roxbury Youth Initiative Summer)',
  'Phillips Brooks House Association (Roxbury Youth Initiative Term)',
  'Phillips Brooks House Association (Small Claims Advisory Service)',
  'Phillips Brooks House Association (South Boston Afterschool Program)',
  'Phillips Brooks House Association (South Boston Outreach Summer)',
  'Phillips Brooks House Association (Strong Women, Strong Girls)',
  'Phillips Brooks House Association (Student Labor Action Movement)',
  'Phillips Brooks House Association (Student Theater Advancing Growth & Empowerment)',
  'Phillips Brooks House Association (Suffolk Prison Education)',
  'Phillips Brooks House Association (The Athena Program)',
  'Phillips Brooks House Association (Undergraduate Legal Committee)',
  "Phillips Brooks House Association (Women's Empowerment and Prison Education Program)",
  'Phillips Brooks House Association (Y2Y Harvard Square)',
  'Phillips Brooks House Association (Youth Leadership Initiative)',
  'Phillips Brooks House Association (Youth Recreation Program)',
  'Photography Club',
  'Poker Club',
  'Pole Dancing Club',
  'Polish Society',
  'Pops Orchestra',
  'Pre-Dental Society',
  'Pre-Veterinary Society',
  'Premedical Society',
  'PRIMUS',
  'Program for International Education',
  'Project for Asian and International Relations',
  'Psychedelics Club',
  'Puerto Rican Students',
  'Puzzle Club',
  'Quantitative Traders',
  'Quantum Computing Association',
  'Queer Advocates',
  'Queer InteRfaith Community',
  'Queer Students Association',
  'Radcliffe Choral Society',
  'Radcliffe Pitches',
  'Raising Autism Awareness!',
  'RAZA',
  'Real Estate Investment Group',
  'Republican Club',
  'Research Association',
  'Reserve Officer Training Corps Association',
  'Response',
  'Right to Life',
  'River Charles Ensemble',
  'Robotics Club',
  'Romanian Association',
  'Room 13',
  'Russian-Speaking Students Association',
  'Salon for the Sciences and Humanities',
  'Satire V',
  'Science Club for Girls',
  'Science Fiction Association',
  'Science Olympiad',
  'Senior Class Committee',
  'Service with a Name',
  'Sexual Education',
  'Shades',
  'Sikh Student Association',
  'Singapore, Indonesia and Malaysia Association',
  'Smart Woman Securities',
  'Society of Anthropologists',
  'Society of Arab Students',
  'Society of Black Scientists and Engineers',
  'Society of Harvard-Undergraduate Magicians',
  'Society of Hispanic Professional Engineers',
  'Society of Physics Students',
  'Society of Women Engineers',
  'SoulFood Undergraduate Christian Fellowship',
  'South African Society',
  'South Asian Association',
  "South Asian Men's Collective",
  'South Asian Music Association',
  "South Asian Women's Collective",
  'South Asians for Forward-Thinking Advocacy and Research',
  'South Slavic Society',
  'Spanish Association',
  'Special Olympics',
  'Sports Analysis Collective',
  'Sports Lab',
  'Stand-Up Comics Society',
  'Steelpan Ensemble',
  'STRIVE',
  'Student Art Collective',
  'Student Astronomers',
  'Student Chapter of Storywish',
  'Student Chapter of the Massachusetts Menstrual Equity Coalition',
  'Student German Club',
  'Students American Lung Cancer Screening Initiative',
  'Students for Myanmar',
  'Students for the Exploration and Development of Space',
  'Students French Club',
  'Students Inspire',
  'Students Piano Society',
  'Swiss Society',
  'Symphony Society',
  'Synapse Chapter',
  'Taiwan Leadership Conference',
  'Taiwanese Cultural Society',
  'Tamid Chapter',
  'Tap Company',
  'Task Force for Asian American Progressive Advocacy and Studies',
  'TEATRO!',
  'Tempus',
  'Texas Club',
  'The Fleur-de-Lis Club',
  'The Franklin Fellowship',
  'The Harvard Advocate',
  'The Harvard Crimson',
  'The Harvard Undergraduate Research Journal',
  'The Ivy',
  'The Kali Praxi',
  'The Oak Club',
  'The Salient',
  'The Seneca',
  'Theologos Society',
  'Think Tank',
  'Three Letter Acronym',
  'THUD',
  'Tibetan Cultural Association',
  'Turkish Student Association',
  'Undergraduate Association',
  'Undergraduate Society',
  'Undergraduate Women of Harvard Athletics',
  'Undergraduates of New Zealand',
  'UNICEF Club',
  'Union Society ',
  'Unique',
  'United Nations Association',
  'University Band',
  'Venture Capital Group',
  'Ventures',
  'Veritas Forum',
  'Veritones',
  'Veterans Organization',
  'Vietnamese Association',
  'Voters Choose',
  'Wellness Educators',
  "Whistler's Society",
  'Wind Ensemble',
  'Wine Society',
  'Wireless Club',
  'Women In Business',
  'Women in Computer Science',
  'Women In Entrepreneurship',
  'Women in Foreign Policy',
  'Women in Law Association',
  'Women in Medicine',
  'Women In STEM',
  'Women of Color Collective',
  "Women's Cabinet",
  'Woodbridge International Society',
  'World Model United Nations',
  'World Pre-Health Conference',
  'Writing and Public Service Initiative',
];
