import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloudinaryUploadWidget from 'src/components/CloudinaryUploadWidget';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import { countryList } from 'src/constants/countryList';
import { statesList } from 'src/constants/stateList';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { RootStateType } from '../redux/store';

dayjs.extend(utc);
dayjs.extend(timezone);

function FreshmanRegister() {
  const navigate = useNavigate();

  const registerOrderDeadline = useSelector(
    (state: RootStateType) => state.constants.registerOrderDeadline,
  );

  const [firstName, setFirstName] = useState<string>('');
  const [middleName, setMiddleName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [preferredName, setPreferredName] = useState<string>('');

  const [secondarySchool, setSecondarySchool] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [city, setCity] = useState<string>('');

  const [yearbookInterest, setYearbookInterest] = useState<boolean>(true);

  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [privacyOption, setPrivacyOption] = useState<number>(1);

  const [photoLink, setPhotoLink] = useState<string>('');

  const [orderCount, setOrderCount] = useState<number>(0);
  const [parentAd, setParentAd] = useState<string>('');

  const [submittingInfo, setSubmittingInfo] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent<number>) => {
    setPrivacyOption(parseInt(JSON.stringify(event.target.value), 10));
  };

  const submitBioform = () => {
    if (submittingInfo) return;
    setSubmittingInfo(true);

    const validators = {
      firstName: firstName.length > 0,
      lastName: lastName.length > 0,
      secondarySchool: secondarySchool.length > 0,
      country: country.length > 0,
      city: city.length > 0,
      email: email.length > 0,
      phone: phone.length > 0,
    };

    if (!Object.values(validators).every((v) => v)) {
      // eslint-disable-next-line no-alert
      alert('Please fill out all required fields');
      setSubmittingInfo(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/freshman_register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        middleName,
        lastName,
        preferredName,
        secondarySchool,
        country,
        state,
        city,
        privacyOption,
        email,
        phone,
        photoLink,
        orderCount,
        parentAd,
        yearbookInterest,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          if (data.data.url !== '') {
            window.location = data.data.url;
          } else {
            navigate('/success_payment', { replace: true });
          }
        } else {
          // eslint-disable-next-line no-alert
          alert('There was an error submitting your information');
        }
        setSubmittingInfo(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(
          'There was an error submitting your information. Please try again later.',
        );
        setSubmittingInfo(false);
      });
  };

  return (
    <>
      <Header />

      <Box
        sx={{
          width: '100%',
          pt: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Freshman Register</Typography>
      </Box>

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Thank you for your interest in the Register, Harvard&apos;s only
              publication containing the incoming class!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              The Class of 2028 Register is a hardbound reference book that
              includes photos of your classmates as well as secondary school and
              hometown information. The Register is the only tangible historical
              record that captures your entry into Harvard as first-year.
            </Typography>
          </Grid>

          {dayjs
            .utc()
            .tz('America/New_York')
            .isBefore(registerOrderDeadline) && (
            <Grid item xs={12}>
              <Typography variant="body1">
                The <b>extended</b> deadline for submitting your information for
                the Register is June 15th. After this deadline, we are unable to
                change any of the photo or privacy settings you indicate below.
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="body1">
              {dayjs
                .utc()
                .tz('America/New_York')
                .isBefore(registerOrderDeadline) &&
                'The deadline for pre-ordering Registers is June 1st.'}
              All books can be picked up during move-in day. More details will
              be emailed to the class in August.
            </Typography>
          </Grid>

          {dayjs
            .utc()
            .tz('America/New_York')
            .isBefore(registerOrderDeadline) && (
            <Grid item xs={12}>
              <Typography variant="body1">
                Please note that the biographical information you choose to
                include in the book, along with your photo, will be visible in
                the Register.
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="body1">
              If you have questions regarding the Register, please contact us at{' '}
              <a href="mailto:inquiries@harvardyearbook.com">
                inquiries@harvardyearbook.com
              </a>
              . We will get back to you as soon as possible!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Student Name</Typography>
          </Grid>

          {dayjs
            .utc()
            .tz('America/New_York')
            .isBefore(registerOrderDeadline) && (
            <Grid item xs={12}>
              <Typography variant="body1">
                Please enter your name exactly as you registered with Harvard
                College. If you wish to appear in the book by a different first
                name, please enter it into the preferred name field below.
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="first-name"
              label="Student's First Name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="middle-name"
              label="Student's Middle Name"
              variant="outlined"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="last-name"
              label="Student's Last Name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="preferred-name"
              label="Student's Preferred Name"
              variant="outlined"
              value={preferredName}
              onChange={(e) => setPreferredName(e.target.value)}
            />
          </Grid>

          {dayjs
            .utc()
            .tz('America/New_York')
            .isBefore(registerOrderDeadline) && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">Secondary School</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Please enter the secondary school from which you graduated.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="secondary-school"
                  label="School Name"
                  variant="outlined"
                  value={secondarySchool}
                  onChange={(e) => setSecondarySchool(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5">Hometown</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Please enter your hometown information.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <InputLabel required id="country">
                  Country
                </InputLabel>
                <Select
                  required
                  fullWidth
                  id="country"
                  label-id="country"
                  variant="outlined"
                  value={country}
                  onChange={(e: SelectChangeEvent<string>) =>
                    setCountry(e.target.value)
                  }
                >
                  {Object.keys(countryList).map((countryName) => (
                    <MenuItem key={countryName} value={countryName}>
                      {countryName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {country === 'United States' && (
                <Grid item xs={12}>
                  <InputLabel id="state">State</InputLabel>
                  <Select
                    required
                    fullWidth
                    displayEmpty
                    id="state"
                    label-id="state"
                    variant="outlined"
                    value={state}
                    onChange={(e: SelectChangeEvent<string>) =>
                      setState(e.target.value)
                    }
                  >
                    {Object.keys(statesList).map((stateName) => (
                      <MenuItem key={stateName} value={stateName}>
                        {stateName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="city"
                  label="City"
                  variant="outlined"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5">Privacy</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Please read the options carefully to see what will appear in
                  the book. I give permission for the publication of:
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <InputLabel id="privacy-option">Select</InputLabel>
                <Select
                  required
                  fullWidth
                  labelId="privacy-option"
                  id="select-privacy-option"
                  label="Privacy Option"
                  value={privacyOption}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>All of the information</MenuItem>
                  <MenuItem value={2}>
                    Only my name, hometown, and secondary school
                  </MenuItem>
                  <MenuItem value={3}>
                    Only my name and secondary school
                  </MenuItem>
                  <MenuItem value={4}>Only my name</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5">Photo</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Your photo submission MUST meet the following requirements or{' '}
                  <strong>your picture will NOT appear in the book</strong>.
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  pl: '4%',
                }}
              >
                <List sx={{ listStyleType: 'disc' }}>
                  <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body1">
                      At LEAST <em>500 KB</em> in size
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body1">
                      Head shot, ideally with a white background
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body1">
                      Your face should not be obscured by a hat, sunglasses,
                      etc.
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'list-item' }}>
                    <Typography variant="body1">
                      JPG/JPEGs are preferred
                    </Typography>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Do not worry about cropping the photo. Please do not upload
                  scans of photos unless the source image is large and the DPI
                  is set high.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  If you choose not to submit a photo or if your photograph does
                  not meet our standards, a filler photo will used in place of
                  your picture.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Please note that this is NOT the submission for your Harvard
                  ID photo. The photo you upload for the register need not be
                  the same as your Harvard ID photo.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  If you would not like to submit a photo, you may skip past
                  this section.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  <b>
                    Please note that in order to upload a photo, you must enter
                    your first and last name so that we can properly connect
                    your photo to your submission!
                  </b>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CloudinaryUploadWidget
                  uwConfig={{
                    cloudName: 'harvard-yearbook',
                    uploadPreset: 'b266p2uw',
                    folder: 'register_389',
                  }}
                  fileName={`${firstName}_${lastName}`}
                  openWidget={firstName !== '' && lastName !== ''}
                  setPhotoLink={setPhotoLink}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="h5">Contact Information</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Please give us your contact information in case we have any
              questions regarding your information. We will ONLY contact you if
              we have problems with your submitted information.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email (do not enter your high school email)"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="phone"
              label="Phone Number"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>

          {dayjs
            .utc()
            .tz('America/New_York')
            .isBefore(registerOrderDeadline) && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">Yearbook Interest</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={yearbookInterest}
                      onChange={(e) => {
                        setYearbookInterest(e.target.checked);
                      }}
                    />
                  }
                  label="I am interested in joining the Harvard Yearbook 
              Publications team"
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="h5">Purchase</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              {dayjs
                .utc()
                .tz('America/New_York')
                .isBefore(registerOrderDeadline) &&
                'Save money by preordering your Register now!'}
              Regular price books are $50.{' '}
              {dayjs
                .utc()
                .tz('America/New_York')
                .isBefore(registerOrderDeadline) && (
                <b>
                  Please note that the deadline for pre-ordering Registers is
                  June 1st, 2024 11:59PM.
                </b>
              )}
            </Typography>
          </Grid>

          {dayjs
            .utc()
            .tz('America/New_York')
            .isBefore(registerOrderDeadline) && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  We also offer Congratulatory Parent Ads! You will receive a
                  complimentary book and prime space for a special message to
                  your student in the back of the book. Upon placing your order,
                  please send photos and other materials for the congratulatory
                  ad to{' '}
                  <a href="mailto:register@harvardyearbook.com">
                    register@harvardyearbook.com
                  </a>
                  .
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  All donations are tax-deductible. Our EIN is 04-6042150. Books
                  will be available for pickup on first-year move-in day. More
                  details will be emailed to the class in August.
                </Typography>
              </Grid>{' '}
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="body1">
              I would like to order this many freshman registers for pickup:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="quantity"
              label="Quantity"
              variant="outlined"
              value={orderCount}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                if (Number.isNaN(parseInt(e.target.value, 10)) || value < 0) {
                  setOrderCount(0);
                } else {
                  setOrderCount(parseInt(e.target.value, 10));
                }
              }}
            />

            <br />

            {dayjs
              .utc()
              .tz('America/New_York')
              .isBefore(registerOrderDeadline) && (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={parentAd}
                onChange={(e) => setParentAd(e.target.value)}
              >
                <FormControlLabel
                  value="silver"
                  control={<Radio />}
                  label="I would like to be a Silver Patron (1/8 Page Black and 
                White Ad), with one complimentary book, $150"
                />
                <FormControlLabel
                  value="platinum"
                  control={<Radio />}
                  label="I would like to be a Platinum Patron (1/4 Page Black and
                White Ad), with one complimentary book, $250"
                />
                <FormControlLabel
                  value="crimson"
                  control={<Radio />}
                  label="I would like to be a Crimson Patron (1/2 Page Black and 
                White Ad), with one complimentary book, $400"
                />
                <FormControlLabel
                  value="john_harvard"
                  control={<Radio />}
                  label="I would like to be a John Harvard Patron (Full Page Black
                and White Ad), with two complimentary books, $800"
                />
              </RadioGroup>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={submitBioform}>
              {submittingInfo ? <CircularProgress /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </>
  );
}

export default FreshmanRegister;
