import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

dayjs.extend(dayOfYear);

interface Row {
  submissionId: string;
  studentFirstName: string;
  studentLastName: string;
  studentEmail: string;
  studentPhone: string;
  parentFirstName: string;
  parentLastName: string;
  parentEmail: string;
  parentPhone: string;
  parentAdId: string;
  parentAd: string;
  parentAdDonorName: string;
  parentAdEmbossText: string;
  embossText: string;
  embossCount: number;
  pickupCount: number;
  shippedCount: number;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  orderTotal: number;
  paymentStatus: string;
  pickedUpAt: Date;
}

function YearbookOrders() {
  const [requestedData, setRequestedData] = useState<boolean>(false);
  const [searchYear, setSearchYear] = useState<number | null>(null);
  const [downloadYear, setDownloadYear] = useState<number | null>(null);

  const [rows, setRows] = useState<Row[]>([]);

  const [openEditMenu, setOpenEditMenu] = useState<boolean>(false);
  const [openPickedUpMenu, setOpenPickedUpMenu] = useState<boolean>(false);
  const [openPickedUpRemoveMenu, setOpenPickedUpRemoveMenu] =
    useState<boolean>(false);
  const [editRow, setEditRow] = useState<Row | null>(null);

  const downloadYearRegistersParams = useMemo(() => {
    const params = new URLSearchParams();
    params.append('year', JSON.stringify(downloadYear));

    return params;
  }, [downloadYear]);

  useEffect(() => {
    if (!requestedData) return;

    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        year: searchYear,
      }),
    })
      .then((res) => res.json())
      .then((res) => setRows(res.data.rows));
  }, [requestedData]);

  const columns: readonly GridColDef[] = [
    { field: 'submissionId', headerName: 'Submission ID', minWidth: 170 },
    {
      field: 'studentFirstName',
      headerName: 'Student First Name',
      minWidth: 170,
    },
    {
      field: 'studentLastName',
      headerName: 'Student Last Name',
      minWidth: 170,
    },
    { field: 'studentEmail', headerName: 'Student Email', minWidth: 170 },
    { field: 'studentPhone', headerName: 'Student Phone', minWidth: 170 },

    {
      field: 'parentFirstName',
      headerName: 'Parent First Name',
      minWidth: 170,
    },
    { field: 'parentLastName', headerName: 'Parent Last Name', minWidth: 170 },
    { field: 'parentEmail', headerName: 'Parent Email', minWidth: 170 },
    { field: 'parentPhone', headerName: 'Parent Phone', minWidth: 170 },

    { field: 'parentAdId', headerName: 'Parent Ad ID', minWidth: 170 },
    { field: 'parentAd', headerName: 'Parent Ad Tier', minWidth: 170 },
    {
      field: 'parentAdDonorName',
      headerName: 'Parent Ad Donor Name',
      minWidth: 170,
    },
    {
      field: 'parentAdEmbossText',
      headerName: 'Parent Ad Emboss Text',
      minWidth: 170,
    },

    { field: 'classYear', headerName: 'Class Year', minWidth: 170 },

    { field: 'embossText', headerName: 'Emboss Text', minWidth: 170 },
    { field: 'embossCount', headerName: '# Embossed', minWidth: 170 },
    { field: 'pickupCount', headerName: '# Pickup', minWidth: 170 },
    { field: 'shippedCount', headerName: '# Shipped', minWidth: 170 },

    { field: 'address', headerName: 'Address', minWidth: 170 },
    { field: 'address2', headerName: 'Address 2', minWidth: 170 },
    { field: 'city', headerName: 'City', minWidth: 170 },
    { field: 'state', headerName: 'State', minWidth: 170 },
    { field: 'zip', headerName: 'ZIP', minWidth: 170 },
    { field: 'country', headerName: 'Country', minWidth: 170 },
    { field: 'orderTotal', headerName: 'Order Total', minWidth: 170 },
    { field: 'paymentStatus', headerName: 'Payment Status', minWidth: 170 },
    {
      field: 'stripeInvoiceId',
      headerName: 'Stripe Invoice ID',
      minWidth: 170,
    },

    {
      field: 'pickedUpButton',
      headerName: 'Picked Up?',
      minWidth: 100,
      cellClassName: 'pickedUpButton',
      renderCell: (row: GridRenderCellParams) => {
        if (row.row.paymentStatus === 'paid') {
          if (row.value) {
            return (
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  setOpenPickedUpRemoveMenu(true);
                  setEditRow(row.row);
                }}
              >
                {row.value}
              </Button>
            );
          }

          return (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setOpenPickedUpMenu(true);
                setEditRow(row.row);
              }}
            >
              Pick Up
            </Button>
          );
        }

        return null;
      },
    },
    {
      field: 'edit',
      type: 'actions',
      headerName: 'Edit',
      minWidth: 50,
      cellClassName: 'edit',
      getActions: ({ row }: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => {
            setOpenEditMenu(true);
            setEditRow(row);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Yearbook Orders</Typography>
      </Box>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setRequestedData(true);
            }}
          >
            Get Orders for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={searchYear}
            onChange={(e) => {
              setSearchYear(parseInt(e.target.value, 10));
              setRequestedData(false);
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            href={`${
              process.env.REACT_APP_BACKEND_URL
            }/staff/download_yearbook_orders${
              downloadYearRegistersParams.size > 0 ? '?' : ''
            }${downloadYearRegistersParams.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            Download CSV for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={downloadYear}
            onChange={(e) => {
              setDownloadYear(parseInt(e.target.value, 10));
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      {requestedData && (
        <Box
          sx={{
            pl: '10%',
            width: '80%',
            pt: 4,
            display: 'grid',
            height: '800px',
          }}
        >
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            ignoreValueFormatterDuringExport
            rows={rows}
            columns={columns}
            getRowId={(row) => row.submissionId}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  fileName: `yearbook_orders_${searchYear}`,
                },
              },
            }}
          />
        </Box>
      )}

      <Dialog
        open={openPickedUpMenu}
        scroll="body"
        maxWidth="xs"
        onClose={() => setOpenPickedUpMenu(false)}
      >
        <DialogTitle>
          Are you sure that you want to mark this as picked up?
        </DialogTitle>
        <DialogActions>
          <Button color="error" onClick={() => setOpenPickedUpMenu(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders/pickup`,
                  {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenPickedUpMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPickedUpRemoveMenu}
        scroll="body"
        maxWidth="xs"
        onClose={() => setOpenPickedUpRemoveMenu(false)}
      >
        <DialogTitle>
          Are you sure that you want to mark this as *not* picked up?
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            onClick={() => setOpenPickedUpRemoveMenu(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders/pickup`,
                  {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenPickedUpRemoveMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={openEditMenu}
        scroll="body"
        maxWidth="sm"
        onClose={() => setOpenEditMenu(false)}
      >
        <DialogTitle>Edit Freshman Register Bioform</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update this person&apos;s bioform.
          </DialogContentText>

          <br />

          <Stack direction="column" spacing={2}>
            <TextField
              label="Submission ID"
              value={editRow?.submissionId}
              disabled
            />
            <TextField
              label="Student First Name"
              value={editRow?.studentFirstName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, studentFirstName: e.target.value });
              }}
            />
            <TextField
              label="Student Last Name"
              value={editRow?.studentLastName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, studentLastName: e.target.value });
              }}
            />
            <TextField
              label="Student Email"
              value={editRow?.studentEmail}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, studentEmail: e.target.value });
              }}
            />
            <TextField
              label="Student Phone"
              value={editRow?.studentPhone}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, studentPhone: e.target.value });
              }}
            />
            <TextField
              label="Parent First Name"
              value={editRow?.parentFirstName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentFirstName: e.target.value });
              }}
            />
            <TextField
              label="Parent Last Name"
              value={editRow?.parentLastName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentLastName: e.target.value });
              }}
            />
            <TextField
              label="Parent Email"
              value={editRow?.parentEmail}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentEmail: e.target.value });
              }}
            />
            <TextField
              label="Parent Phone"
              value={editRow?.parentPhone}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentPhone: e.target.value });
              }}
            />
            <TextField
              label="Parent Ad ID"
              value={editRow?.parentAdId}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentAdId: e.target.value });
              }}
            />
            <TextField
              label="Parent Ad Tier"
              value={editRow?.parentAd}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentAd: e.target.value });
              }}
            />
            <TextField
              label="Parent Ad Donor Name"
              value={editRow?.parentAdDonorName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentAdDonorName: e.target.value });
              }}
            />
            <TextField
              label="Parent Ad Emboss Text"
              value={editRow?.parentAdEmbossText}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    parentAdEmbossText: e.target.value,
                  });
              }}
            />
            <TextField
              label="Emboss Text"
              value={editRow?.embossText}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, embossText: e.target.value });
              }}
            />
            <TextField
              label="# Embossed"
              value={editRow?.embossCount}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    embossCount: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="# Pickup"
              value={editRow?.pickupCount}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    pickupCount: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="# Shipped"
              value={editRow?.shippedCount}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    shippedCount: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="Address"
              value={editRow?.address}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, address: e.target.value });
              }}
            />
            <TextField
              label="Address 2"
              value={editRow?.address2}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, address2: e.target.value });
              }}
            />
            <TextField
              label="City"
              value={editRow?.city}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, city: e.target.value });
              }}
            />
            <TextField
              label="State"
              value={editRow?.state}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, state: e.target.value });
              }}
            />
            <TextField
              label="ZIP"
              value={editRow?.zip}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, zip: e.target.value });
              }}
            />
            <TextField
              label="Country"
              value={editRow?.country}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, country: e.target.value });
              }}
            />
            <TextField
              label="Order Total"
              value={editRow?.orderTotal}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    orderTotal: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="Payment Status"
              value={editRow?.paymentStatus}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, paymentStatus: e.target.value });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditMenu(false)}>Cancel</Button>
          <Button
            color="error"
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders/payment`,
                  {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Remove Payment
          </Button>
          <Button
            color="error"
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders`,
                  {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders`,
                  {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                      studentFirstName: editRow.studentFirstName,
                      studentLastName: editRow.studentLastName,
                      studentEmail: editRow.studentEmail,
                      studentPhone: editRow.studentPhone,

                      parentFirstName: editRow.parentFirstName,
                      parentLastName: editRow.parentLastName,
                      parentEmail: editRow.parentEmail,
                      parentPhone: editRow.parentPhone,

                      parentAdId: editRow.parentAdId,
                      parentAd: editRow.parentAd,
                      parentAdDonorName: editRow.parentAdDonorName,
                      parentAdEmbossText: editRow.parentAdEmbossText,

                      embossText: editRow.embossText,
                      embossCount: editRow.embossCount,

                      pickupCount: editRow.pickupCount,
                      shippedCount: editRow.shippedCount,

                      address: editRow.address,
                      address2: editRow.address2,
                      city: editRow.city,
                      state: editRow.state,
                      zip: editRow.zip,
                      country: editRow.country,

                      orderTotal: editRow.orderTotal,
                      paymentStatus: editRow.paymentStatus,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_orders`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default YearbookOrders;
