import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import Header from 'src/components/Header';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookIcon from '@mui/icons-material/Book';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import ConstantsPage from 'src/pages/staff/ConstantsPage';
import FreshmanRegisterOrders from 'src/pages/staff/FreshmanRegisterOrders';
import SalesReport from 'src/pages/staff/SalesReport';
import YearbookOrders from 'src/pages/staff/YearbookOrders';
import { useNavigate } from 'react-router-dom';
import DashboardPage from './staff/Dashboard';
import YearbookBioforms from './staff/YearbookBioforms';

type ListItemType = {
  text: string;
  icon: React.ReactNode;
  page:
    | 'dashboard'
    | 'yearbook_orders'
    | 'freshman_register_orders'
    | 'yearbook_bioforms'
    | 'sales'
    | 'constants';
  pageComponent: React.ReactNode;
};

const mainListItems: ListItemType[] = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    page: 'dashboard',
    pageComponent: <DashboardPage />,
  },
  {
    text: 'Yearbook Orders',
    icon: <BookIcon />,
    page: 'yearbook_orders',
    pageComponent: <YearbookOrders />,
  },
  {
    text: 'Register Orders',
    icon: <BookIcon />,
    page: 'freshman_register_orders',
    pageComponent: <FreshmanRegisterOrders />,
  },
  {
    text: 'Yearbook Bioforms',
    icon: <BookIcon />,
    page: 'yearbook_bioforms',
    pageComponent: <YearbookBioforms />,
  },
  {
    text: 'Sales **',
    icon: <AttachMoneyIcon />,
    page: 'sales',
    pageComponent: <SalesReport />,
  },
  {
    text: 'Constants',
    icon: <SettingsIcon />,
    page: 'constants',
    pageComponent: <ConstantsPage />,
  },
];

function Staff({ page }: { page: string }) {
  const navigate = useNavigate();

  return (
    <>
      <Header />

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Drawer
          variant="permanent"
          PaperProps={{
            sx: {
              top: '100px',
              height: 'calc(100% - 100px)',
              width: '200px',
            },
          }}
        >
          <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
            <List dense>
              {mainListItems.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    selected={item.page === page}
                    onClick={() => {
                      navigate(`/staff/${item.page}`, { replace: true });
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Drawer>

        <Box
          sx={{
            position: 'absolute',
            left: '200px',
            width: 'calc(100% - 200px)',
          }}
        >
          {mainListItems
            .filter((item) => item.page === page)
            .map((item) => item.pageComponent)}
        </Box>
      </Box>
    </>
  );
}

export default Staff;
