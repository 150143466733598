import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import { countryList } from 'src/constants/countryList';
import { statesList } from 'src/constants/stateList';

function YearbookOrder() {
  const navigate = useNavigate();

  const [studentFirstName, setStudentFirstName] = useState<string>('');
  const [studentLastName, setStudentLastName] = useState<string>('');
  const [studentEmail, setStudentEmail] = useState<string>('');
  const [studentPhone, setStudentPhone] = useState<string>('');

  const [parentFirstName, setParentFirstName] = useState<string>('');
  const [parentLastName, setParentLastName] = useState<string>('');
  const [parentEmail, setParentEmail] = useState<string>('');
  const [parentPhone, setParentPhone] = useState<string>('');

  const [orderParentAd, setOrderParentAd] = useState<boolean>(false);

  const [parentAdTier, setParentAdTier] = useState<string>('');
  const [parentAdDonorName, setParentAdDonorName] = useState<string>('');
  const [parentAdEmboss, setParentAdEmboss] = useState<string>('');

  const [orderEmboss, setOrderEmboss] = useState<boolean>(false);

  const [embossValidators, setEmbossValidators] = useState<boolean[]>([
    false,
    false,
  ]);
  const [embossCount, setEmbossCount] = useState<number>(0);
  const [embossText, setEmbossText] = useState<string>('');

  const [pickupCount, setPickupCount] = useState<number>(0);
  const [shippedCount, setShippedCount] = useState<number>(0);

  const [address, setAddress] = useState<string>('');
  const [address2, setAddress2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [country, setCountry] = useState<string>('United States');

  const [submittingInfo, setSubmittingInfo] = useState<boolean>(false);

  const submitBioform = () => {
    if (submittingInfo) return;
    setSubmittingInfo(true);

    const validators = {
      studentFirstName: studentFirstName !== '',
      studentLastName: studentLastName !== '',
      studentEmail: studentEmail !== '',
      studentPhone: studentPhone !== '',

      parentFirstName: parentFirstName !== '',
      parentLastName: parentLastName !== '',
      parentEmail: parentEmail !== '',
      parentPhone: parentPhone !== '',

      parentAdValidators: orderParentAd
        ? parentAdTier !== '' &&
          parentAdDonorName !== '' &&
          parentAdEmboss !== ''
        : true,
      embossValidators: orderEmboss
        ? embossValidators.every((v) => v) &&
          embossText !== '' &&
          embossCount > 0
        : true,
      pickupCount: pickupCount >= 0,
      shippedCount: shippedCount >= 0,

      addressValidators:
        orderParentAd || orderEmboss || shippedCount > 0
          ? address !== '' && city !== '' && zip !== '' && country !== ''
          : true,
      unitedStatesValidator:
        country === 'United States' &&
        (orderParentAd || orderEmboss || shippedCount > 0)
          ? state !== ''
          : true,
    };

    if (!Object.values(validators).every((v) => v)) {
      // eslint-disable-next-line no-alert
      alert('Please fill out all required fields');
      setSubmittingInfo(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/yearbook/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        studentFirstName,
        studentLastName,
        studentEmail,
        studentPhone,

        parentFirstName,
        parentLastName,
        parentEmail,
        parentPhone,

        orderParentAd,
        parentAdTier,
        parentAdDonorName,
        parentAdEmboss,

        orderEmboss,
        embossText,
        embossCount,

        pickupCount,
        shippedCount,

        address,
        address2,
        city,
        state,
        zip,
        country,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          if (data.data.url && data.data.url !== '') {
            window.location = data.data.url;
          } else {
            navigate('/success_order', { replace: true });
          }
        } else {
          // eslint-disable-next-line no-alert
          alert('There was an error submitting your information');
        }
        setSubmittingInfo(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(
          'There was an error submitting your information. Please try again later.',
        );
        setSubmittingInfo(false);
      });
  };

  return (
    <>
      <Header />

      <Box
        sx={{
          width: '100%',
          pt: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Yearbook Orders</Typography>
      </Box>

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <i>Harvard 389</i> captures the moments and memories that matter
              for the Harvard Class of 2025.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              The Harvard Yearbook features the graduating class of Harvard in
              its award-winning book. Compilation from all aspects of student
              life including student activities, major shows and performances,
              letters from professors, and house life are portrayed serving as a
              nostalgic keepsake of student life.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              The deadline for placing a <b>Congratulatory Parent Ad</b> is{' '}
              <b>December 18th, 2025 11:59PM</b>. If you have a design in mind,
              we will help you create it. We can also design the parent ad for
              you from scratch, with rounds of proofing to get it just right.
              For more information, please scroll to the bottom of this page.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Student Information</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="student-first-name"
              label="Student's First Name"
              variant="outlined"
              value={studentFirstName}
              onChange={(e) => setStudentFirstName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="student-last-name"
              label="Student's Last Name"
              variant="outlined"
              value={studentLastName}
              onChange={(e) => setStudentLastName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="student-email"
              label="Student's Email"
              variant="outlined"
              value={studentEmail}
              onChange={(e) => setStudentEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="student-phone"
              label="Student's Phone Number"
              variant="outlined"
              value={studentPhone}
              onChange={(e) => setStudentPhone(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Parent Information</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="parent-first-name"
              label="Parent's First Name"
              variant="outlined"
              value={parentFirstName}
              onChange={(e) => setParentFirstName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="parent-last-name"
              label="Parent's Last Name"
              variant="outlined"
              value={parentLastName}
              onChange={(e) => setParentLastName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="parent-email"
              label="Parent's Email"
              variant="outlined"
              value={parentEmail}
              onChange={(e) => setParentEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="parent-phone"
              label="Parent's Phone Number"
              variant="outlined"
              value={parentPhone}
              onChange={(e) => setParentPhone(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Parent Ad Orders</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              To submit a congratulatory message to your student in the Harvard
              Yearbook, become a patron for the yearbook! Templates and examples
              of parent ads are available in our{' '}
              <a
                href="https://static1.squarespace.com/static/6480fe1b85305143e810e5e8/t/66f2d681932cc1079127bd1b/1727190661650/HYP+389+Parent+Ads.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Parent Ad Style Guide
              </a>
              .
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              To submit materials for your parent ad, please see our Parent Ad
              Policies.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Patrons will also receive a copy of a yearbook embossed with the
              student&apos;s name for no additional charge.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={orderParentAd}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setOrderParentAd(e.target.checked);
                  }}
                />
              }
              label="Yes, I would like to become a sponsor and submit an ad for my student."
            />
          </Grid>

          {orderParentAd && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Thank you for being a patron! Please choose from one of the
                  sponsor packages below.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={parentAdTier}
                  onChange={(e) => setParentAdTier(e.target.value)}
                >
                  <FormControlLabel
                    value="silver"
                    control={<Radio />}
                    label="I would like to be a Silver Patron (1/8 Page Color Ad): one embossed yearbook shipped, $300"
                  />
                  <FormControlLabel
                    value="platinum"
                    control={<Radio />}
                    label="I would like to be a Platinum Patron (1/4 Page Color Ad): one embossed yearbook shipped, $500"
                  />
                  <FormControlLabel
                    value="crimson"
                    control={<Radio />}
                    label="I would like to be a Crimson Patron (1/2 Page Color Ad): one embossed yearbook shipped, $800"
                  />
                  <FormControlLabel
                    value="john_harvard"
                    control={<Radio />}
                    label="I would like to be a John Harvard Patron (Full Page Color Ad): one embossed yearbook shipped, $1,600"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Please enter your name as you would like it to appear on the
                  donor page of the yearbook. You may enter
                  &quot;Anonymous&quot; if you do not wish your name to appear.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="parent-ad-donor-name"
                  label="Donor Page Text (e.g. 'The Smith Family' or 'Anonymous')"
                  variant="outlined"
                  value={parentAdDonorName}
                  onChange={(e) => setParentAdDonorName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  You will receive a complimentary book embossed with the name
                  of your graduate. Please type the exact name to be embossed on
                  the cover (27 char max, no special letters):
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="parent-ad-emboss"
                  label="Embossed Name"
                  variant="outlined"
                  value={parentAdEmboss}
                  onChange={(e) => setParentAdEmboss(e.target.value)}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="h5">Embossed Orders</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              The deadline for ordering an embossed yearbook is March 17th, 2025
              11:59PM.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              You can emboss the front cover of the yearbook with the
              graduate&apos;s name for an additional fee of $30. Please note
              that embossed yearbooks must be shipped (and not picked up) and
              will be shipped out in May.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={orderEmboss}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setOrderEmboss(e.target.checked);
                  }}
                />
              }
              label="Yes, I would like my graduate's name embossed on the cover."
            />
          </Grid>

          {orderEmboss && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Please read the following carefully to ensure the embossing
                  text is correct for your yearbook order.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={embossValidators[0]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setEmbossValidators([
                            e.target.checked,
                            embossValidators[1],
                          ]);
                        }}
                      />
                    }
                    label="I understand that ALL embossed books WILL BE SHIPPED to the shipping address entered below."
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={embossValidators[1]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setEmbossValidators([
                            embossValidators[0],
                            e.target.checked,
                          ]);
                        }}
                      />
                    }
                    label="I would like my graduate's name embossed on the cover exactly as typed below (27 characters max) for an additional $30 per book. I understand that the system cannot accept special characters or letters."
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  Please input the embossing text as you would like it to appear
                  (no special characters):
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="emboss-text"
                  label="Embossing Text"
                  variant="outlined"
                  value={embossText}
                  onChange={(e) => setEmbossText(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  I would like to order this many embossed yearbooks, shipped:
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="emboss-count"
                  label="Embossing Order Count"
                  variant="outlined"
                  value={embossCount}
                  onChange={(e) => setEmbossCount(parseInt(e.target.value, 10))}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="h5">Regular Orders</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              The deadline for ordering a yearbook for pickup (at the COOP)
              during exam period is March 17th, 2025 11:59PM. Order by January
              31st, 2024 11:59PM to get special discounted pricing!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              The deadline for ordering a shipped yearbook is March 17th, 2025
              11:59PM. Yearbooks will be shipped around mid-May. Shipping and
              Handling Rates: $10 Domestic, $20 International.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              I would like to order this many yearbook(s), for pickup in May,
              during finals period and Senior Week:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="pickup-count"
              label="Pickup Order Count"
              variant="outlined"
              value={pickupCount}
              onChange={(e) => setPickupCount(parseInt(e.target.value, 10))}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              I would like to order this many yearbook(s), shipped to you in
              May:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="shipped-count"
              label="Shipped Order Count"
              variant="outlined"
              value={shippedCount}
              onChange={(e) => setShippedCount(parseInt(e.target.value, 10))}
            />
          </Grid>

          {(orderParentAd || orderEmboss || shippedCount > 0) && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">Shipping Information</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  For students: please do not enter your Harvard (on-campus)
                  mailing address; enter your home address instead. Yearbooks
                  will be shipped around mid-May. Additionally, we discourage
                  international shipping addresses, as these shipments are often
                  lost or delayed.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="address-line"
                  label="Address"
                  variant="outlined"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="address-line-2"
                  label="Address Line 2"
                  variant="outlined"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="city"
                  label="City"
                  variant="outlined"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>

              {country === 'United States' && (
                <Grid item xs={12}>
                  <InputLabel id="state">State *</InputLabel>
                  <Select
                    fullWidth
                    displayEmpty
                    id="state"
                    label-id="state"
                    variant="outlined"
                    value={state}
                    onChange={(e: SelectChangeEvent<string>) =>
                      setState(e.target.value)
                    }
                  >
                    {Object.keys(statesList).map((stateName) => (
                      <MenuItem key={stateName} value={stateName}>
                        {stateName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}

              <Grid item xs={12}>
                <InputLabel required id="country">
                  Country
                </InputLabel>
                <Select
                  required
                  fullWidth
                  id="country"
                  label-id="country"
                  variant="outlined"
                  value={country}
                  onChange={(e: SelectChangeEvent<string>) =>
                    setCountry(e.target.value)
                  }
                >
                  {Object.keys(countryList).map((countryName) => (
                    <MenuItem key={countryName} value={countryName}>
                      {countryName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="zip-code"
                  label="Zip Code"
                  variant="outlined"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={submitBioform}>
              {submittingInfo ? <CircularProgress /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </>
  );
}

export default YearbookOrder;
